body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: aqua;
  height: 100vh;
  width: 100vw;
}

.Body {
  height: 60vh;
  width: 50vw;
}

h1 {
  position: absolute;
  left: 45vw;
  font-size: 4rem;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.Butn {
  color: rgb(252, 252, 252);
  background-color: #0181E9;
  border: 2px solid #0a5694;
  border-radius: 2px;
  margin: 4px;
}

.Form {
  color: aliceblue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

#inputs {
  width: 34vw;
}

#forminput {
  width: 100%;
  margin-bottom: 5px;
  color: black;
  height: 50px;
}


.outPut {
  display: flex;
  justify-content: space-around;
  align-items: center;
}


.qr {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 27px;
}

.qr img {
  height: 158px;
  width: 180px;
}

.outPut button.notCopied {
  margin-top: 10px;
  background-color: chocolate;
  color: white;
  height: 30px;
}

.outPut button.copied {
  margin-top: 10px;
  background-color: white;
  color: black;
  height: 30px;
}

.shortUrl {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shortUrl span {
  font-size: 30px;
}